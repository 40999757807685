import React from 'react';

const RegisterButton = (props) => {
  return (
    <div
      id="Register_1"
      onClick={props.clickRegisterHandler}
      className={props.className}
      aria-hidden="true"
    >
      {props.title}
    </div>
  );
};

export { RegisterButton };
