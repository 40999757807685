import React, { useState } from 'react';
import BoostrapForm from 'react-bootstrap/Form';
import successCheck from '../assets/success-check.png';
import { RegisterButton } from './Buttons';

const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Form = ({ program, stateChanger }) => {
  const [fieldErrors, setFieldErrors] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [npi, setNpi] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [optOutOfMealCredit, setOptOutOfMealCredit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const fieldConfigs = {
    firstName: { setter: setFirstName, getter: firstName, required: true },
    lastName: { setter: setLastName, getter: lastName, required: true },
    email: {
      setter: setEmail,
      getter: email,
      required: true,
      isValid: (value) => emailRgx.test(value),
      errorText: 'Please enter a valid email address'
    },
    npi: {
      setter: setNpi,
      getter: npi,
      required: true,
      isValid: (value) => {
        if (/^\d{10}$/.test(value) === true || /N\/A\b/.test(value.toUpperCase()) === true) {
          return true;
        }
      },
      errorText: 'NPI must be 10 digits or N/A'
    },
    city: { setter: setCity, getter: city, required: true },
    state: { setter: setState, getter: state, required: true, id: 'state' },
    country: { setter: setCountry },
    specialty: { setter: setSpecialty, getter: specialty, required: true, id: 'specialty' },
    affiliation: { setter: setAffiliation, getter: affiliation, required: true },
    phoneNumber: { setter: setPhoneNumber, getter: phoneNumber, required: true },
    optOutOfMealCredit: { setter: setOptOutOfMealCredit }
  };

  const handleSuccessClick = () => {
    setIsSuccess(true);
  };

  // add or removes color classes to select fields
  const changeSelectColor = (fieldName) => {
    return (evt) => {
      const fieldConfig = fieldConfigs[fieldName];
      if (evt.target.value === '') {
        document.getElementById(fieldConfig.id).classList.add('grey-select');
      } else {
        document.getElementById(fieldConfig.id).classList.remove('grey-select');
        document.getElementById(fieldConfig.id).classList.add('black');
      }
    };
  };

  const validateField = (fieldName) => {
    const fieldConfig = fieldConfigs[fieldName];
    if (fieldConfig.isValid && !fieldConfig.isValid(fieldConfig.getter)) {
      // if there is a validation function and it returns false, set the error
      setFieldErrors({ ...fieldErrors, [fieldName]: fieldConfig.errorText });
    }
  };

  const handleFieldChange = (fieldName) => {
    return (evt) => {
      if (fieldName === 'specialty' || fieldName === 'state') {
        changeSelectColor(fieldName)(evt);
      }
      const fieldConfig = fieldConfigs[fieldName];
      const errors = { ...fieldErrors };
      delete errors[fieldName];
      setFieldErrors(errors);
      if (fieldName === 'optOutOfMealCredit') {
        fieldConfig.setter(evt.target.checked);
        return;
      }
      fieldConfig.setter(evt.target.value);
    };
  };

  const isValid = () => {
    return Object.keys(fieldErrors).length === 0;
  };

  const hasAllRequiredFields = () => {
    const requiredFields = Object.keys(fieldConfigs).filter(
      (fieldName) => fieldConfigs[fieldName].required
    );

    const errors = { ...fieldErrors };
    requiredFields.forEach((fieldName) => {
      if (!fieldConfigs[fieldName].getter) {
        errors[fieldName] = 'This field is required';
      }
    });
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!hasAllRequiredFields() || !isValid()) {
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      state: state,
      npi: npi,
      phone_number: phoneNumber,
      country: country,
      city: city,
      specialty: specialty,
      affiliation: affiliation,
      opt_out_of_meal_credit: optOutOfMealCredit,
      program: program.id
    };

    fetch('/api/registrant/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => response.json());
    // .then((resp_data) => console.log(resp_data));
    handleSuccessClick();
    stateChanger(true);
  };

  return (
    <div>
      {!isSuccess ? (
        <div className="register-form-wrapper">
          <form onSubmit={handleSubmit} className="form-layout">
            <BoostrapForm.Control
              value={firstName}
              maxLength={100}
              onChange={handleFieldChange('firstName')}
              type="text"
              required
              isInvalid={!!fieldErrors.firstName}
              placeholder="First Name*"
              className="modal-input"
            />
            {fieldErrors.firstName && <span className="form-error">{fieldErrors.firstName}</span>}
            <BoostrapForm.Control
              value={lastName}
              maxLength={100}
              onChange={handleFieldChange('lastName')}
              type="text"
              required
              isInvalid={!!fieldErrors.lastName}
              placeholder="Last Name*"
              className="modal-input"
            />
            {fieldErrors.lastName && <span className="form-error">{fieldErrors.lastName}</span>}
            <BoostrapForm.Control
              value={affiliation}
              maxLength={100}
              onChange={handleFieldChange('affiliation')}
              type="text"
              required
              isInvalid={!!fieldErrors.affiliation}
              placeholder="Affiliation*"
              className="modal-input"
            />
            {fieldErrors.affiliation && (
              <span className="form-error">{fieldErrors.affiliation}</span>
            )}
            <BoostrapForm.Control
              value={email}
              onChange={handleFieldChange('email')}
              onBlur={() => validateField('email')}
              isInvalid={!!fieldErrors.email}
              type="email"
              required
              placeholder="Email*"
              className="modal-input"
            />
            {fieldErrors.email && <span className="form-error">{fieldErrors.email}</span>}
            <BoostrapForm.Control
              value={phoneNumber}
              maxLength={15}
              onChange={handleFieldChange('phoneNumber')}
              type="text"
              required
              isInvalid={!!fieldErrors.phoneNumber}
              placeholder="Mobile Phone&dagger;"
              className="modal-input"
            />
            {fieldErrors.phoneNumber && (
              <span className="form-error">{fieldErrors.phoneNumber}</span>
            )}
            <div
              style={{
                fontStyle: 'italic',
                fontSize: '10px',
                marginRight: '149px',
                marginTop: '-14px'
              }}
            >
              &dagger;for notifications only
            </div>
            <BoostrapForm.Control
              value={city}
              maxLength={50}
              onChange={handleFieldChange('city')}
              type="text"
              required
              isInvalid={!!fieldErrors.city}
              placeholder="Affiliation City*"
              className="modal-input"
            />
            {fieldErrors.city && <span className="form-error">{fieldErrors.city}</span>}
            <BoostrapForm.Select
              value={state}
              onChange={handleFieldChange('state')}
              required
              isInvalid={!!fieldErrors.state}
              id="state"
              name="state"
              className="modal-input"
            >
              <option value="">Affiliation State*</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="MP">Northern Marianas Islands</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="VI">Virgin Islands</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </BoostrapForm.Select>
            {fieldErrors.state && <span className="form-error">{fieldErrors.state}</span>}
            <BoostrapForm.Control
              value={country}
              maxLength={50}
              onChange={handleFieldChange('country')}
              type="text"
              placeholder="Country"
              className="modal-input"
            />
            <BoostrapForm.Control
              value={npi}
              maxLength={10}
              onChange={handleFieldChange('npi')}
              onBlur={() => validateField('npi')}
              isInvalid={!!fieldErrors.npi}
              type="text"
              required
              placeholder="NPI Number&dagger;"
              className="modal-input"
            />
            <div
              style={{
                fontStyle: 'italic',
                fontSize: '10px',
                marginRight: '50px',
                marginTop: '-14px'
              }}
            >
              &dagger;Please enter N/A if you do not have an NPI
            </div>
            {fieldErrors.npi && <span className="form-error">{fieldErrors.npi}</span>}
            <BoostrapForm.Select
              value={specialty}
              onChange={handleFieldChange('specialty')}
              required
              isInvalid={!!fieldErrors.specialty}
              id="specialty"
              name="specialty"
              className="modal-input"
            >
              <option value="">Specialty*</option>
              <option value="Child Neurologist">Child Neurologist</option>
              <option value="Neurologist">Neurologist</option>
              <option value="NP/PA">NP/PA</option>
              <option value="Nurse">Nurse</option>
              <option value="Other HCP">Other HCP</option>
              <option value="Other">Other</option>
            </BoostrapForm.Select>
            {fieldErrors.specialty && <span className="form-error">{fieldErrors.specialty}</span>}

            <div
              style={{
                fontStyle: 'italic',
                fontSize: '10px',
                marginRight: '184px',
                marginTop: '-14px',
                paddingTop: '1px'
              }}
            >
              *Required field
            </div>
            <div
              id="Register_2"
              className="button-background"
              onClick={handleSubmit}
              role="button"
              tabIndex={0}
            >
              <RegisterButton title="REGISTER" className="register-button purple-background" />
            </div>
          </form>
        </div>
      ) : (
        <div className="success-view brandon-font">
          <img src={successCheck} className="success-check" alt="success" />
          <div className="success-text">Thank you for registering.</div>
        </div>
      )}
    </div>
  );
};

export default Form;
