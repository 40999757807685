import React from 'react';
import ZogenixLogoNew from '../assets/Zogenix_Logo_RGB22.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="isi">
        <h4 className="bolder purple" style={{ fontSize: '20px' }}>
          INDICATIONS AND USAGE
        </h4>
        <p className="isi-text">
          FINTEPLA is indicated for the treatment of seizures associated with Dravet syndrome (DS)
          and Lennox-Gastaut syndrome (LGS) in patients 2 years of age and older.
        </p>
        <h4 className="bolder purple" style={{ fontSize: '20px' }}>
          IMPORTANT SAFETY INFORMATION
        </h4>
        <p className="isi-text bold">
          BOXED WARNING: VALVULAR HEART DISEASE and PULMONARY ARTERIAL HYPERTENSION
        </p>
        <ul className="bold isi-text" style={{ marginLeft: '-15px' }}>
          <li>
            There is an association between serotonergic drugs with 5-HT2B receptor agonist
            activity, including fenfluramine (the active ingredient in FINTEPLA), and valvular heart
            disease and pulmonary arterial hypertension.
          </li>
          <li>
            Echocardiogram assessments are required before, during, and after treatment with
            FINTEPLA.
          </li>
          <li>FINTEPLA is available only through a restricted program called the FINTEPLA REMS.</li>
        </ul>
        <p className="isi-text bold">CONTRAINDICATIONS</p>
        <p className="isi-text">
          FINTEPLA is contraindicated in patients with hypersensitivity to fenfluramine or any of
          the excipients in FINTEPLA and with concomitant use, or within 14 days of the
          administration of monoamine oxidase inhibitors because of an increased risk of serotonin
          syndrome.
        </p>
        <p className="isi-text bold">WARNINGS AND PRECAUTIONS</p>
        <p className="isi-text">
          <span className="bolder">
            Valvular Heart Disease and Pulmonary Arterial Hypertension (see Boxed Warning):
          </span>{' '}
          Because of the association between serotonergic drugs with 5-HT2B receptor agonist
          activity, including fenfluramine (the active ingredient in FINTEPLA), and valvular heart
          disease (VHD) and pulmonary arterial hypertension (PAH), cardiac monitoring via
          echocardiogram is required prior to starting treatment, during treatment, and after
          treatment with FINTEPLA concludes. Cardiac monitoring via echocardiogram can aid in early
          detection of these conditions. In clinical trials for DS and LGS of up to 3 years in
          duration, no patient receiving FINTEPLA developed VHD or PAH.
        </p>
        <p className="isi-text">
          <span style={{ textDecoration: 'underline' }}>Monitoring</span>: Prior to starting
          treatment, patients must undergo an echocardiogram to evaluate for VHD and PAH.
          Echocardiograms should be repeated every 6 months, and once at 3-6 months post treatment
          with FINTEPLA.
        </p>
        <p className="isi-text">
          The prescriber must consider the benefits versus the risks of initiating or continuing
          treatment with FINTEPLA if any of the following signs are observed via echocardiogram:
          valvular abnormality or new abnormality; VHD indicated by mild or greater aortic
          regurgitation or moderate or greater mitral regurgitation, with additional characteristics
          of VHD (eg, valve thickening or restrictive valve motion); PAH indicated by elevated right
          heart/pulmonary artery pressure (PASP &gt;35mmHg).
        </p>
        <p className="isi-text">
          <span className="bolder">FINTEPLA REMS Program (see Boxed Warning):</span> FINTEPLA is
          available only through a restricted distribution program called the FINTEPLA Risk
          Evaluation and Mitigation Strategy (REMS) Program. Prescribers must be certified by
          enrolling in the FINTEPLA REMS. Prescribers must counsel patients receiving FINTEPLA about
          the risk of valvular heart disease and pulmonary arterial hypertension, how to recognize
          signs and symptoms of valvular heart disease and pulmonary arterial hypertension, the need
          for baseline (pretreatment) and periodic cardiac monitoring via echocardiogram during
          FINTEPLA treatment, and cardiac monitoring after FINTEPLA treatment. Patients must enroll
          in the FINTEPLA REMS and comply with ongoing monitoring requirements. The pharmacy must be
          certified by enrolling in the FINTEPLA REMS and must only dispense to patients who are
          authorized to receive FINTEPLA. Wholesalers and distributors must only distribute to
          certified pharmacies. Further information is available at&nbsp;
          <a
            href="https://www.finteplarems.com/"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            www.FinteplaREMS.com
          </a>{' '}
          or by telephone at 1-877-964-3649.
        </p>
        <p className="isi-text">
          <span className="bolder">Decreased Appetite and Decreased Weight:</span> FINTEPLA can
          cause decreases in appetite and weight. Decreases in weight appear to be dose related.
          Approximately half of the patients with LGS and most patients with DS resumed the expected
          measured increases in weight during the open-label extension studies. Weight should be
          monitored regularly during treatment with FINTEPLA, and dose modifications should be
          considered if a decrease in weight is observed.
        </p>
        <p className="isi-text">
          <span className="bolder">Somnolence, Sedation, and Lethargy:</span> FINTEPLA can cause
          somnolence, sedation, and lethargy. Other central nervous system (CNS) depressants,
          including alcohol, could potentiate these effects of FINTEPLA. Prescribers should monitor
          patients for somnolence and sedation and should advise patients not to drive or operate
          machinery until they have gained sufficient experience on FINTEPLA to gauge whether it
          adversely affects their ability to drive or operate machinery.
        </p>
        <p className="isi-text">
          <span className="bolder">Suicidal Behavior and Ideation:</span> Antiepileptic drugs
          (AEDs), including FINTEPLA, increase the risk of suicidal thoughts or behaviors in
          patients taking these drugs for any indication. Patients treated with an AED for any
          indication should be monitored for the emergence or worsening of depression, suicidal
          thoughts or behaviors, or any unusual changes in mood or behavior.
        </p>
        <p className="isi-text">
          Anyone considering prescribing FINTEPLA or any other AED must balance the risk of suicidal
          thoughts or behaviors with the risks of untreated illness. Epilepsy and many other
          illnesses for which AEDs are prescribed are themselves associated with morbidity and
          mortality and an increased risk of suicidal thoughts and behaviors. Should suicidal
          thoughts and behaviors emerge during treatment, consider whether the emergence of these
          symptoms in any given patient may be related to the illness being treated.
        </p>
        <p className="isi-text">
          <span className="bolder">Withdrawal of Antiepileptic Drugs:</span> As with most AEDs,
          FINTEPLA should generally be withdrawn gradually because of the risk of increased seizure
          frequency and status epilepticus. If withdrawal is needed because of a serious adverse
          reaction, rapid discontinuation can be considered.
        </p>
        <p className="isi-text">
          <span className="bolder">Serotonin Syndrome:</span> Serotonin syndrome, a potentially
          life-threatening condition, may occur with FINTEPLA, particularly during concomitant
          administration of FINTEPLA with other serotonergic drugs, including, but not limited to,
          selective serotonin-norepinephrine reuptake inhibitors (SNRIs), selective serotonin
          reuptake inhibitors (SSRIs), tricyclic antidepressants (TCAs), bupropion, triptans,
          dietary supplements (eg, St. John’s Wort, tryptophan), drugs that impair metabolism of
          serotonin (including monoamine oxidase inhibitors [MAOIs], which are contraindicated with
          FINTEPLA), dextromethorphan, lithium, tramadol, and antipsychotics with serotonergic
          agonist activity. Patients should be monitored for the emergence of signs and symptoms of
          serotonin syndrome, which include mental status changes (eg, agitation, hallucinations,
          coma), autonomic instability (eg, tachycardia, labile blood pressure, hyperthermia),
          neuromuscular signs (eg, hyperreflexia, incoordination), and/or gastrointestinal symptoms
          (eg, nausea, vomiting, diarrhea). If serotonin syndrome is suspected, treatment with
          FINTEPLA should be stopped immediately and symptomatic treatment should be started.
        </p>
        <p className="isi-text">
          <span className="bolder">Increase in Blood Pressure:</span> FINTEPLA can cause an increase
          in blood pressure. Rare cases of significant elevation in blood pressure, including
          hypertensive crisis, has been reported in adult patients treated with fenfluramine,
          including patients without a history of hypertension. In clinical trials for DS and LGS of
          up to 3 years in duration, no pediatric or adult patient receiving FINTEPLA developed
          hypertensive crisis. Monitor blood pressure in patients treated with FINTEPLA.
        </p>
        <p className="isi-text">
          <span className="bolder">Glaucoma:</span> Fenfluramine can cause mydriasis and can
          precipitate angle closure glaucoma. Consider discontinuing treatment with FINTEPLA in
          patients with acute decreases in visual acuity or ocular pain.
        </p>
        <p className="isi-text bold">ADVERSE REACTIONS</p>
        <p className="isi-text">
          The most common adverse reactions observed in DS studies (incidence at least 10% and
          greater than placebo) were decreased appetite; somnolence, sedation, lethargy; diarrhea;
          constipation; abnormal echocardiogram; fatigue, malaise, asthenia; ataxia, balance
          disorder, gait disturbance; blood pressure increased; drooling, salivary hypersecretion;
          pyrexia; upper respiratory tract infection; vomiting; decreased weight; fall; status
          epilepticus.
        </p>
        <p className="isi-text">
          The most common adverse reactions observed in the LGS study (incidence at least 10% and
          greater than placebo) were diarrhea; decreased appetite; fatigue; somnolence; vomiting.
        </p>
        <p className="isi-text bold">DRUG INTERACTIONS</p>
        <p className="isi-text">
          Strong CYP1A2, CYP2B6, or CYP3A Inducers: Coadministration with strong CYP1A2, CYP2B6, or
          CYP3A inducers will decrease fenfluramine plasma concentrations. If coadministration of a
          strong CYP1A2, CYP2B6, or CYP3A inducer with FINTEPLA is necessary, monitor the patient
          for reduced efficacy and consider increasing the dosage of FINTEPLA as needed. If a strong
          CYP1A2, CYP2B6, or CYP3A inducer is discontinued during maintenance treatment with
          FINTEPLA, consider gradual reduction in the FINTEPLA dosage to the dose administered prior
          to initiating the inducer.
        </p>
        <p className="isi-text">
          Strong CYP1A2 or CYP2D6 Inhibitors: Coadministration with strong CYP1A2 or CYP2D6
          inhibitors will increase fenfluramine plasma concentrations. If FINTEPLA is coadministered
          with strong CYP1A2 or CYP2D6 inhibitors, the maximum daily dosage of FINTEPLA is 20 mg. If
          a strong CYP1A2 or CYP2D6 inhibitor is discontinued during maintenance treatment with
          FINTEPLA, consider gradual increase in the FINTEPLA dosage to the dose recommended without
          CYP1A2 or CYP2D6 inhibitors. If FINTEPLA is coadministered with stiripentol and a strong
          CYP1A2 or CYP2D6 inhibitor, the maximum daily dosage of FINTEPLA is 17 mg.
        </p>
        <p className="isi-text bold">USE IN SPECIFIC POPULATIONS</p>
        <p className="isi-text">
          Administration to patients with hepatic impairment is not recommended.
        </p>
        <p className="isi-text bold">
          To report SUSPECTED ADVERSE REACTIONS, contact Zogenix Inc. at 1-866-964-3649
          (1-866-Zogenix) or FDA at 1-800-FDA-1088 or&nbsp;
          <a
            href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            www.fda.gov/medwatch
          </a>
          .
        </p>
        <p className="isi-text bold">
          Please see full&nbsp;
          <a
            href="https://www.zogenix.com/pi/Fintepla-prescribing-information.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            Prescribing Information
          </a>
          &nbsp;including Boxed Warning, for additional important information on FINTEPLA.
        </p>
      </div>
      <div id="logo">
        <img src={ZogenixLogoNew} alt="Zogenix" className="footer-logo" />
        <div className="logo-text">UCB welcomes Zogenix, Inc. to UCB.</div>
        <div className="logo-text">
          FINTEPLA<sup>®</sup> is a registered trademark of the UCB Group of Companies.
        </div>
        <div className="logo-text">
          &copy;2022 UCB, Inc., Smyrna, GA 30080. All rights reserved.
        </div>
        <div className="logo-text">Intended for residents of the United States only.</div>
        <div className="logo-text1">US-FIN12-2200216</div>
      </div>
    </footer>
  );
};

export default Footer;
