import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.css';
import header from '../assets/header.png';
import header2 from '../assets/header2.png';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Header = () => {
  return (
    <div className="App">
      <div className="yoy">
        <img
          className="hide-image"
          style={{ maxWidth: '100%', width: '100%' }}
          src={header}
          alt="Novartis"
        />
        <img
          className="hide-image2"
          style={{ maxWidth: '100%', width: '100%', display: 'none' }}
          src={header2}
          alt=""
        />
      </div>
      <div className="header-container">
        <div className="isi">
          <h4 className="bold purple join-us">
            Join Us for a Fintepla Connect Virtual Program
          </h4>
          <p className="bold header-text" style={{ fontSize: '21px' }}>
            Learn about the FINTEPLA phase 3 clinical program in Dravet syndrome and Lennox-Gastaut
            syndrome. Please click on a program to register!
          </p>
          <p className="bold header-text" style={{ fontSize: '20px' }}>
            Additional information on our expert moderators can be found by clicking on their name.
          </p>
        </div>
      </div>
    </div>
  );
};

export { Header };
