/* eslint-disable react/no-danger */
import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';
// import BoostrapForm from 'react-bootstrap/Form';
// import WebFont from 'webfontloader';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import Form from './components/Form';
import Footer from './components/Footer';
import { Header } from './components/Header';
import { RegisterButton } from './components/Buttons';

const App = () => {
  const [programs, setPrograms] = useState([]);
  // const [zipcode, setZipcode] = useState('');
  // const [radius, setRadius] = useState('');
  const [isSpeakerModalVisible, setIsSpeakerModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [isRegisterClicked, setIsRegisterClicked] = useState(false);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    fetch('/api/programs/')
      .then((response) => response.json())
      .then((data) => setPrograms(data));

    window.addEventListener('load', () => {
      const lines = gsap.utils.toArray('.speaker-content');

      lines.forEach((line) => {
        gsap.from(line, {
          y: 100,
          opacity: 0,
          scrollTrigger: {
            trigger: line,
            start: 'top bottom',
            end: 'top 80%',
            scrub: true
          }
        });
      });
    });
  }, []);

  const handleSpeakerClick = (program) => {
    setSelectedProgram(program);
    setIsSpeakerModalVisible(true);
  };

  const handleRegisterClick = (program) => {
    setSelectedProgram(program);
    setIsRegisterModalVisible(true);
  };

  const closeRegisterClick = () => {
    setIsRegisterClicked(false);
  };

  const handleSpeakerModalClose = () => setIsSpeakerModalVisible(false);
  const handleRegisterModalClose = () => {
    setIsRegisterModalVisible(false);
    closeRegisterClick();
  };

  return (
    <div className="App">
      <div className="body-wrapper">
        <Header />

        <div className="speaker-section">
          {programs.map((program, idx) => (
            <div className="speaker-content" key={idx}>
              <div className="program-info">
                <div className="program-heading">
                  {dayjs(program.datetime).format('MMM D, YYYY')}
                </div>

                <div className="program-info-spacing program-dates">
                  <span dangerouslySetInnerHTML={{ __html: program.start_time }} />
                </div>
                <a
                  id="Speaker_Modal"
                  href=""
                  className="black program-dates bold speaker-padding"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSpeakerClick(program);
                  }}
                >
                  Speaker: {program.speaker.name}, {program.speaker.credentials}
                </a>
                <div className="black program-dates">{program.speaker.institution}</div>
                <div className="black program-dates">
                  {program.speaker.institution_city}, {program.speaker.institution_state}
                </div>
              </div>
              <div>
                {!program.isMax && (
                  <RegisterButton
                    title="REGISTER"
                    className="register-button red-background"
                    clickRegisterHandler={(e) => {
                      e.preventDefault();
                      handleRegisterClick(program);
                    }}
                  />
                )}
                {program.isMax && (
                  <div className="disable-register max-program-display">REGISTER</div>
                )}
                {program.isMax && (
                  <div
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      paddingTop: '10px',
                      fontWeight: '700'
                    }}
                    className="max-program-display"
                  >
                    This program is currently at capacity. Please check back soon for future
                    programs in this area.
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="contact-text">
          Please contact{' '}
          <a href="mailto:UCBSupport@meisolutions.com" className="black">
            UCBSupport@meisolutions.com
          </a>{' '}
          with any questions about the program.
        </div>
        <Footer />
      </div>
      {/* Speaker Modal */}
      <Modal show={isSpeakerModalVisible} onHide={handleSpeakerModalClose}>
        <Modal.Header>
          <i
            className="bi bi-x-lg cursor-pointer"
            style={{ marginLeft: 'auto' }}
            onClick={(e) => setIsSpeakerModalVisible(false)}
            aria-hidden="true"
          />

          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="speaker-modal-container">
            <div className="speaker-image">
              <img
                src={selectedProgram.speaker ? selectedProgram.speaker.photo : ''}
                alt="SpeakerImage"
                style={{ width: '125px' }}
              />
            </div>
            <div className="speaker-modal-bio">
              <div className="speaker-modal-name">
                {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''}
              </div>
              <div className="speaker-modal-bio-text">
                {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''},{' '}
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: selectedProgram.speaker ? selectedProgram.speaker.bio : ''
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Register Modal */}
      <Modal show={isRegisterModalVisible} onHide={handleRegisterModalClose}>
        <Modal.Header>
          <i
            className="bi bi-x-lg cursor-pointer"
            style={{ marginLeft: 'auto' }}
            onClick={handleRegisterModalClose}
            aria-hidden="true"
          />

          <Modal.Title />
        </Modal.Header>
        <Modal.Body>
          <div className="register-form-layout">
            {!isRegisterClicked && (
              <div className="register-program-info bold">
                <div className="small-phone-text" style={{ fontSize: '17px' }}>
                  {dayjs(selectedProgram.datetime ? selectedProgram.datetime : '').format(
                    'MMM D, YYYY'
                  )}
                </div>
                <div className="small-phone-text" style={{ fontSize: '17px' }}>
                  <span dangerouslySetInnerHTML={{ __html: selectedProgram.start_time }} />
                </div>
              </div>
            )}

            {!isRegisterClicked && (
              <div className="register-modal-speaker">
                Speaker: {selectedProgram.speaker ? selectedProgram.speaker.name : ''},{' '}
                {selectedProgram.speaker ? selectedProgram.speaker.credentials : ''}
              </div>
            )}
            <Form program={selectedProgram} stateChanger={setIsRegisterClicked} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
